.chatWidget {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 450px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* overflow: hidden; */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(4%);
  /* opacity: 0; */
  z-index: 1000;
}

.chatWidget.open {
  transform: translateY(0);
  opacity: 1;
}

.chatButton {
  position: fixed;
  font-size: large;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1001;
}

.chatWindow {
  height: 450px;
  /* overflow-y: scroll; */
  border-bottom: 1px solid #ccc;
  display: block !important;
}
.chatheight {
  max-height: 350px !important;
  overflow-y: scroll;
}
.chatLog {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff;
  color: #fff;
}

.bot-message {
  align-self: flex-start;
  background-color: #e9e9e9;
}

.chatInput {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: large;
}

.sendButton {
  width: calc(45% - 20px);
  margin-top: 10px;
  font-size: 12px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  align-self: flex-end;
}

.closeButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 992px) {
  .chatWidget {
    max-width: 290px;
    bottom: 61px;
    right: 5px;
  }
}
